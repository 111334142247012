@import 'config';

#services {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: max(calc(100vh - 44px), 750px);
  > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 2rem;
  }
  h2 {
    font-weight: 300;
    align-self: start;
  }
  h3 {
    font-weight: 700;
    font-size: 1.75rem;
    color: $light-red;
  }
  .call-box {
    display: flex;
    align-items: center;
    p {
      font-size: 1.5rem;
      font-weight: 600;
      &:nth-of-type(2) {
        font-weight: 700;
        text-align: center;
        background-color: $green;
        padding: 0.25rem 1.5rem;
        border-radius: 10px;

        a {
          color: $light-text;
          font-size: 1.25rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  #services {
    height: fit-content;
    gap: 2rem;
    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1.25rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
}