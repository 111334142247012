@import 'config';

#equipment {
  display: grid;
  gap: 4rem;

  > div {
    margin-left: 300px;
  }

  aside {
    position: fixed;
    width: 250px;
  }

  .selectors {
    display: grid;
    margin-top: 3rem;
    gap: 1rem;

    .link {
      font-size: 1.5rem;
      font-weight: 400;
      text-align: right;
    }

    .active {
      font-weight: 700;
    }
  }

  .call-box {
    padding: 1rem;
    color: $dark-text;
    border: 2px solid $light-red;
    border-radius: 20px;
    background-color: white;

    p:nth-of-type(2) {
      font-weight: 700;
      margin-top: 1rem;
      padding: 0.5rem 1rem;
      text-align: center;
      border-radius: 10px;
      background-color: $green;

      a {
        font-size: 1.25rem;
        color: $light-text;
      }
    }
  }
  h1 {
    width: 100%;
    margin-bottom: 2rem;
    border-bottom: 4px solid $light-red;
  }

  .equipment-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .product-card {
    position: relative;
    display: grid;
    padding: 1rem;
    border: 1px solid lightgray;
    border-radius: 10px;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;

    img {
      max-height: calc(250px - 2rem);
    }

    > div {
      display: grid;
      grid-template-rows: 2fr 3fr 1fr;
    }

    p {
      font-size: 1rem;
    }

    a {
      text-transform: uppercase;
      font-family: 'Roboto Mono', monospace;
      font-weight: 700;
      font-size: 0.8rem;
      align-self: end;
      color: $light-red;
      margin-right: 0.5rem;
      &:hover {
        color: $dark-red;
      }
    }
  }

  .dropdown {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  #equipment {
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin: 44px 0 0 0;
    gap: 1rem;

    aside {
      display: contents;
      position: relative;
      width: auto;
    }

    > div {
      margin: 0;
    }

    .call-box {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      text-align: center;
      border: none;

      p:nth-of-type(2) {
        max-width: 300px;
        margin: 1rem auto;
      }
    }
    .selectors {
      display: none;
    }
    h1 {
      display: none;
    }
    .dropdown {
      display: block;
      position: relative;
      margin-bottom: 1rem;
      select {
        padding: 0.5rem 1.5rem;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        border: 1px solid black;
        border-radius: 10px;
        cursor: pointer;
        background: transparent;
        appearance: none;
      }
      .faChevronDown {
        position: absolute;
        z-index: -1;
        top: 12px;
        right: 12px;
      }
    }

    .equipment-list {
      display: flex;
      flex-direction: column;
    }

    .product-card {
      img {
        max-height: 150px;
      }
      div {
        max-height: fit-content;
      }
    }
  }
}