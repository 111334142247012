@import 'config';

#home-page {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: max(calc(100vh - 44px), 750px);
  > div {
    display: flex;
    gap: 2rem;
    height: 600px;
    width: 1250px;
  }
}

.left-side {
  width: 60%;
  display: grid;
  grid-template-rows: 2fr 3fr 1fr;
  h1 {
    font-size: 2rem;
    font-weight: 100;
    line-height: 1;
    span {
      margin-top: 1rem;
      color: $light-red;
      font-size: 3rem;
      display: block;
      font-weight: 700;
      line-height: 1;
    }
  }
  p {
    max-width: 500px;
  }
  img {
    margin-top: auto;
  }
}

.right-side {
  max-width: fit-content;
}

@media screen and (max-width: 1024px) {
  #home-page {
    .left-side {
      width: 100%;
      height: 100%;
      text-align: center;
      justify-content: center;
      p {
        max-width: 500px;
      }
      img {
        margin: 0 auto;
      }
    }
    .right-side {
      display: none;
    }
  }
}