@import 'config';

html {
  box-sizing: border-box;
  //color-scheme: light dark;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}

main {
  margin: 44px auto 0 auto;
  padding: 2rem 1rem;
  width: min(1250px, 100% - 4rem);
}

img, svg, video {
  max-width: 100%;
  display: block;
}

a, .link {
  color: $dark-text;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}

@import 'navbar';
@import 'home';
@import 'services';
@import 'equipment';
@import 'contact';