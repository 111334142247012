@import 'config';

#contact {
  height: calc(100vh - 44px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  padding: 2rem 8rem;
  h1 {
    font-weight: 300;
  }
  h2 {
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    font-size: 1rem;
    color: $light-red;
  }
  > div {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 1024px) {
  #contact {
    padding: 0.5rem;
    gap: 8rem;
    h1 {
      text-align: center;
      font-size: 1.5rem;
    }
    > div {
      width: 100%;
      display: flex;
      gap: 2rem;
      flex-direction: column;
    }
    h2, a {
      display: block;
      text-align: center;
    }
  }
}